<script>
import CandidatureCard from '@/components/general/CandidatureCard'
import ModalMessages from '@/components/general/ModalMessages'
export default {
  components: { CandidatureCard, ModalMessages },
  name: 'UnderAnalysis',
  data () {
    return {
      company: {},
      position: {},
      showModal: false,
      getApplicationsAnalysis: [],
      isLoading: true
    }
  },
  methods: {
    handleShowModalMessages (item) {
      this.company = { name: item.company.name, image: item.company.logo }
      this.position = { name: item.title, id: item.id }
      this.showModal = !this.showModal
    }
  },
  created () {
    this.$store.dispatch('attemptGetAllPositions', this.selectedWorkspace.name.toLowerCase()).then((data) => {
      const positions = []
      for (const item of data) {
        positions.push(item.position)
      }
      this.getApplicationsAnalysis = positions.filter(d => d.status === 'no_filter' && d.company.name.toLowerCase() === this.selectedWorkspace.name.toLowerCase()).sort((a, b) => a.title.localeCompare(b.title))
      this.isLoading = false
    })
  }
}
</script>
<template>
  <div class="under-analysis--container">
    <p>{{ $t('internal.mobility:under.analysis.description') }}</p>
    <div class="under-analysis--list">
      <div class="skeleton-under-analysis" v-if="isLoading">
        <v-skeleton-loader class="skeleton-card-under-analysis max-width" height="80px" type="list-item-avatar-two-line" v-for="i in ['a', 'b', 'c']"
          :key="i" />
      </div>
      <candidature-card v-for="item in this.getApplicationsAnalysis" :key="item.id"
        @handleModalMessage="handleShowModalMessages(item)" :candidature="{
          position: {
            company: {
              logo: item.company.logo,
              name: item.company.name
            },
            title: item.title,
            status: item.status,
          },
          stepsLeft: 1,
        }"></candidature-card>
    </div>
    <modal-messages v-if="showModal" :company=company :position=position @close="showModal = false"></modal-messages>
  </div>
</template>
<style lang="scss">
.under-analysis--container {
  .under-analysis--list {
    .skeleton-under-analysis {
      clear: both;
      display: flex;
      flex-direction: column;

      .skeleton-card-under-analysis {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
